import {Button, ButtonGroup, Grid, IconButton, lighten, Paper, Typography} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import themeColors from '../../../assets/theme/colors';
import {localeTexts} from '../../../variables/datagrid';
import {DataGridPro, GridActionsCellItem, useGridApiRef} from '@mui/x-data-grid-pro';
import {graphQLApi} from '../../../services/GraphQLApi';
import {Add, Delete, Edit, FileCopy, OpenInNew} from '@material-ui/icons';
import {ConfirmDialog} from '../../../components/Dialogs';
import {useHistory} from 'react-router-dom';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField
} from '@mui/material';
import moment from 'moment';

export default function AssignmentList(props) {
  const intl = useIntl();
  const tableRef = useGridApiRef();
  const history = useHistory();
  const [filter, setFilter] = useState('active');
  const [queryFilter, setQueryFilter] = useState((props.filter ? props.filter : '') + ' is_active:true');
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyData, setCopyData] = useState(
      {questions: true, templates: true, fields: true, email_settings: true, phoners: true});
  const client = new graphQLApi(useAuthDispatch(), props.history);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(0);
  const editAssignment = (id) => {
    if (props.history.location.pathname.includes('admin/')) {
      props.history.push('/admin/assignments/' + id);
    }
    else {
      props.history.push('/assignment/' + id + '/settings/assignment');
    }
  };
  const copyAssignment = () => {
    setShowCopyDialog(false);
    setIsCopying(true);
    client.mutate(
        '($id:ID!,$name:String,$questions:Boolean,$templates:Boolean,$fields:Boolean,$email_settings:Boolean,$phoners:Boolean)' +
        '{assignmentCopy(id:$id,name:$name,questions:$questions,templates:$templates,fields:$fields,email_settings:$email_settings,phoners:$phoners)}',
        copyData).then(resp => {
      setIsCopying(false);
      if (resp && resp.hasOwnProperty('assignmentCopy')) {
        if (props.history.location.pathname.includes('admin/')) {
          props.history.push('/admin/assignments/' + resp.assignmentCopy);
        }
        else {
          props.history.push('/assignment/' + resp.assignmentCopy + '/settings/assignment');
        }
      }
    });
  };
  const handleAssignmentSelect = (id, _row) => {
    client.mutate('($id:ID!,$index:Int){userSettingDelete(user_id:$id, key:"subjects_filter", index:$index)}', {
      id: authUser().id,
      index: parseInt(id),
    }).then((_r) => {
      props.history.push('/assignment/' + id + '/subjects');
    })
  };
  const handleDeletion = (confirmed) => {
    let assignmentId = props.match.params && props.match.params.assignmentID;
    if (confirmed && showDeleteConfirmation > 0) {
      client.mutate('{assignmentDelete(id:' + showDeleteConfirmation + ')}').then(result => {
        if (result) {
          tableRef.current.updateRows([{id: showDeleteConfirmation, _action: 'delete'}]);
          if (showDeleteConfirmation === assignmentId) {
            history.replace('/assignment');
          }
        }
        setShowDeleteConfirmation(0);
      });
    }
    else {
      setShowDeleteConfirmation(0);
    }
  };

  const columns = [
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.name', defaultMessage: 'Name'}),
      field: 'name',
      width: 250,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignments.list.column.marked_subjects_count',
        defaultMessage: 'Processed not sent',
      }),
      field: 'marked_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.closed_and_sent', defaultMessage: 'Sent'}),
      field: 'closed_sent_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.ready', defaultMessage: 'Ready'}),
      field: 'ready_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.hotleads', defaultMessage: 'Hotleads'}),
      field: 'hot_lead_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({
        id: 'assignments.list.column.subjects_handled_today_count',
        defaultMessage: 'Handled today',
      }),
      field: 'subjects_handled_today_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({
        id: 'assignments.list.column.closed_subjects_count',
        defaultMessage: 'Closed subjects',
      }),
      field: 'closed_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({
        id: 'assignments.list.column.open_subjects_count',
        defaultMessage: 'Open subjects',
      }),
      field: 'open_subjects_count',
      sortable: false,
      width: 75,
      minWidth: 75,
      align: 'center',
    },
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'dateTime',
      valueFormatter: ({ value }) => moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
      minWidth: 100,
      width: 175,
    },
    {
      headerName: intl.formatMessage({id: 'assignments.list.column.manager.name', defaultMessage: 'Manager'}),
      field: 'manager_name',
      resizable: false,
      width: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 150,
      resizable: false,
      getActions: ({id, row}) => [
        <GridActionsCellItem
            icon={<OpenInNew/>}
            label={intl.formatMessage({id: 'assignments.list.action.open', defaultMessage: 'Open in PL'})}
            title={intl.formatMessage({id: 'assignments.list.action.open', defaultMessage: 'Open in PL'})}
            onClick={() => handleAssignmentSelect(id, row)}/>,
        <GridActionsCellItem
            icon={<FileCopy/>}
            label={intl.formatMessage({id: 'assignments.list.action.copy', defaultMessage: 'Copy'})}
            title={intl.formatMessage({id: 'assignments.list.action.copy', defaultMessage: 'Copy'})}
            onClick={() => {
              setCopyData({
                id: id,
                name: row.name + ' ' + intl.formatMessage({id: 'assignments.list.action.copy', defaultMessage: 'Copy'}),
                questions: true,
                templates: true,
                fields: true,
                email_settings: true,
                phoners: true,
              });
              setShowCopyDialog(true);
            }}/>,
        <GridActionsCellItem
            icon={<Edit/>}
            label={intl.formatMessage({id: 'assignments.list.action.edit', defaultMessage: 'Edit'})}
            title={intl.formatMessage({id: 'assignments.list.action.edit', defaultMessage: 'Edit'})}
            onClick={() => editAssignment(id)}/>,
        <GridActionsCellItem
            icon={<Delete/>}
            label={intl.formatMessage({id: 'assignments.list.action.delete', defaultMessage: 'Delete'})}
            title={intl.formatMessage({id: 'assignments.list.action.delete', defaultMessage: 'Delete'})}
            onClick={() => setShowDeleteConfirmation(id)}/>,
      ],
    },
  ];

  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  useEffect(() => {
    fetchRows(queryFilter);
  }, [queryFilter]);

  const autosizeOptions = {
    includeOutliers: true,
    includeHeaders: true,
    outliersFactor: 1.1,
    expand: true,
  };
  const fetchRows = (queryFilter) => {
    setDataIsLoading(true);
    client.query('{' +
        'assignments(' +
        ' filter:{' + queryFilter + '}' +
        ' counts:["subjects_handled_today","closed_subjects","open_subjects","marked_subjects","closed_sent_subjects","ready_subjects","hot_lead_subjects"]' +
        ')' +
        '{data{id name manager{name} consultants{name} created_at' +
        ' subjects_handled_today_count closed_subjects_count open_subjects_count marked_subjects_count closed_sent_subjects_count ready_subjects_count hot_lead_subjects_count}' +
        '}}').then(r => {
      if (r && r.hasOwnProperty('assignments')) {
        setRows(r.assignments.data.map(row => ({
          ...row,
          customer_name: row.customer ? row.customer.name : '',
          contact_name: row.contact ? row.contact.name : '',
          manager_name: row.manager ? row.manager.name : '',
          consultants: row.consultants ? row.consultants.map(c => c.name).join(', ') : '',
        })));
        if (tableRef.current) tableRef.current.updateRows(rows)
      }
      setDataIsLoading(false);
    }).then(() => {
      tableRef.current?.autosizeColumns(autosizeOptions);
    });
  }

  window.addEventListener('resize', () => {
    tableRef.current?.autosizeColumns(autosizeOptions);
  });

  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout.current = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      let callNow = immediate && !timeout.current;
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  const debouncedFetchRows = debounce((search) => {
    const newFilter = queryFilter.split("q:").shift();
    search = search.replaceAll('"', '\\"')
    if (search !== "") {
      setQueryFilter(newFilter);
    }
    else setQueryFilter(newFilter + ' q:"'+ search + '"');
    fetchRows(newFilter + ' q:"'+ search + '"');
  }, 1250);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    let f = '';
    switch (newFilter) {
      default:
      case 'active':
        f = (props.filter ? props.filter : '') + ' is_active:true';
        break;
      case 'passive':
        f = (props.filter ? props.filter : '') + ' is_active:false';
        break;
      case 'others':
        f = 'manager_id_ne:' + authUser().id + ' is_active:true';
        break;
      case 'others_passive':
        f = 'manager_id_ne:' + authUser().id + ' is_active:false';
        break;
      case 'all_active':
        f = 'is_active:true';
        break;
    }
    setQueryFilter(f);
  };

  return (
      <Paper>
        <ConfirmDialog onClose={handleDeletion} open={showDeleteConfirmation > 0}
                       title={intl.formatMessage({
                         id: 'assignments.list.confirm_deletion.title',
                         defaultMessage: 'Confirm deletion',
                       })}
                       message={intl.formatMessage({
                         id: 'assignments.list.confirm_deletion.message',
                         defaultMessage: 'Please confirm that you want to delete the assignment?',
                       })}
        />
        <Dialog open={showCopyDialog} onClose={_e => setShowCopyDialog(false)} maxWidth="sm" fullWidth>
          <DialogContent>
            <FormControl component="fieldset" style={{gap: 15}}>
              <FormLabel component="legend">{intl.formatMessage({
                id: 'assignments.list.dialog.copy.select_data',
                defaultMessage: 'Select what should be copied',
              })}</FormLabel>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={copyData.questions}
                                       onChange={e => setCopyData({...copyData, questions: e.target.checked})}/>}
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.questionnaire', defaultMessage: 'Questionnaire'})}
                />
                <FormControlLabel
                    control={<Checkbox checked={copyData.templates}
                                       onChange={e => setCopyData({...copyData, templates: e.target.checked})}/>}
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.templates', defaultMessage: 'Templates'})}
                />
                <FormControlLabel
                    control={<Checkbox checked={copyData.fields}
                                       onChange={e => setCopyData({...copyData, fields: e.target.checked})}/>}
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.fields', defaultMessage: 'Subject fields'})}
                />
                <FormControlLabel
                    control={<Checkbox checked={copyData.email_settings}
                                       onChange={e => setCopyData({...copyData, email_settings: e.target.checked})}/>}
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.email_settings', defaultMessage: 'Email settings'})}
                />
                <FormControlLabel
                    control={<Checkbox checked={copyData.phoners}
                                       onChange={e => setCopyData({...copyData, phoners: e.target.checked})}/>}
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.phoners', defaultMessage: 'Associated phoners'})}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                    label={intl.formatMessage(
                        {id: 'assignments.list.dialog.copy.name', defaultMessage: 'Assignment name'})}
                    value={copyData.name}
                    onChange={e => setCopyData({...copyData, name: e.target.value})}
                    variant="standard"
                />
              </FormGroup>
            </FormControl>
            {isCopying
                ? <CircularProgress/>
                : <DialogActions style={{justifyContent: 'space-between', marginTop: 15}}>
                  <Button color="info" variant="contained" onClick={_e => setShowCopyDialog(null)}>{intl.formatMessage(
                      {id: 'common.button.cancel'})}</Button>
                  <Button color="primary" variant="contained" onClick={_e => copyAssignment()}>{intl.formatMessage(
                      {id: 'common.button.ok'})}</Button>
                </DialogActions>
            }
          </DialogContent>
        </Dialog>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item style={{paddingLeft: 32}}>
            <Typography variant="h1">
              {intl.formatMessage({id: 'assignments.list.table.heading', defaultMessage: 'Assignments'})}
            </Typography>
          </Grid>
          <Grid item><ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
            <Button variant={filter === 'active' ? 'contained' : 'outlined'}
                    onClick={() => handleFilterChange('active')}>{intl.formatMessage({
              id: 'assignments.list.table.filter.active',
              defaultMessage: 'Active',
            })}</Button>
            <Button variant={filter === 'passive' ? 'contained' : 'outlined'}
                    onClick={() => handleFilterChange('passive')}>{intl.formatMessage({
              id: 'assignments.list.table.filter.passive',
              defaultMessage: 'Passive',
            })}</Button>
            {props.filter &&
                <Button variant={filter === 'others' ? 'contained' : 'outlined'}
                        onClick={() => handleFilterChange('others')}>{intl.formatMessage({
                  id: 'assignments.list.table.filter.others',
                  defaultMessage: 'Others active',
                })}</Button>}
            {props.filter &&
                <Button variant={filter === 'others_passive' ? 'contained' : 'outlined'}
                        onClick={() => handleFilterChange('others_passive')}>{intl.formatMessage({
                  id: 'assignments.list.table.filter.others_passive',
                  defaultMessage: 'Others passive',
                })}</Button>}
            {props.filter &&
                <Button variant={filter === 'all_active' ? 'contained' : 'outlined'}
                        onClick={() => handleFilterChange('all_active')}>{intl.formatMessage({
                  id: 'assignments.list.table.filter.all_active',
                  defaultMessage: 'All active',
                })}</Button>}
          </ButtonGroup></Grid>
          <Grid item style={{paddingRight: 32}}>
            <TextField
                variant="standard"
                size="small"
                placeholder={intl.formatMessage({
                  id: 'assignments.list.table.search',
                  defaultMessage: 'Search',
                })}
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                  debouncedFetchRows(e.target.value);
                }
            }
              />
            {authUser().isAllowed(50) && <IconButton size="small"
              onClick={() => history.push('/assignment/create')}><Add/></IconButton>}
          </Grid>
          <Grid item xs={12} style={{height: 'calc(100vh - 230px)'}}>
            <DataGridPro
                apiRef={tableRef}
                sx={{
                  boxShadow: 0,
                  borderRight: 0,
                  borderLeft: 0,
                  borderBottom: 0,
                  borderTop: 1,
                  borderColor: themeColors.gray[200],
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: `1px solid ${themeColors.gray[200]}`,
                  },
                  '& .MuiDataGrid-columnHeader': {
                    borderRightWidth: 0,
                  },
                  '& .MuiDataGrid-cell': {
                    borderRight: `1px solid ${themeColors.gray[200]}`,
                  },
                  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${themeColors.gray[200]}`,
                  },
                  '& .MuiDataGrid-footerContainer': {
                    borderTop: `1px solid ${themeColors.gray[200]}`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    color: themeColors.gray[200],
                  },
                  '& .row-marked': {
                    backgroundColor: lighten(themeColors.error.main, 0.8),
                    '&.Mui-hovered': {
                      backgroundColor: lighten(themeColors.error.main, 0.7),
                    },
                  },
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                filterModel={{
                  items: [{ columnField: 'name', operatorValue: 'contains', value: search }],
                }}
                columns={columns}
                rows={rows}
                disableColumnMenu
                loading={dataIsLoading}
                disableMultipleSelection
                rowHeight={28}
                headerHeight={35}
                pageSize={1000}
                localeText={localeTexts(intl)}
                pinnedColumns={{left: [columns[0].field], right: ['actions']}}
                getRowClassName={({row}) => (row.marked_subjects_count > 0) ? 'row-marked' : ''}
                onRowClick={({id, row}) => props.history.location.pathname.includes('admin/')
                    ? editAssignment(id)
                    : handleAssignmentSelect(id, row)}
                autosizeOptions={autosizeOptions}
            />
          </Grid>
        </Grid>
      </Paper>
  );
}